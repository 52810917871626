import axios from "axios";

export default {
  onLogin(params, onSuccess, onError) {
    axios
      .post(process.env.VUE_APP_API_URL + "auth/login", params)
      .then(res => {
        if (res.data.success == true) {
          onSuccess(res.data.token, res.data.type);
        } else {
          onError(res.data.message);
        }
      })
      .catch(error => {
        onError(error.response.data);
      });
  },

  //Agency API
  getAllAgencies(headers, onSuccess, onError) {
    axios
      .post(process.env.VUE_APP_API_URL + "agency/all", null, { headers })
      .then(res => {
        if (res.data.success == true) {
          onSuccess(res.data.res);
        } else {
          onError(res.data.message);
        }
      })
      .catch(error => {
        onError(error);
      });
  },
  getAgency(headers, params, onSuccess, onError) {
    axios
      .post(process.env.VUE_APP_API_URL + "agency/profile", params, {
        headers: headers
      })
      .then(res => {
        if (res.data.success == true) {
          onSuccess(res.data.res);
        } else {
          onError(res.data.message);
        }
      })
      .catch(error => {
        onError(error);
      });
  },
  addAgency(headers, params, onSuccess, onError) {
    axios
      .post(process.env.VUE_APP_API_URL + "agency/add", params, {
        headers: headers
      })
      .then(res => {
        if (res.data.success == true) {
          onSuccess(res.data.res);
        } else {
          onError(res.data.message);
        }
      })
      .catch(error => {
        onError(error);
      });
  },
  editAgency(headers, params, onSuccess, onError) {
    axios
      .post(process.env.VUE_APP_API_URL + "agency/update", params, {
        headers: headers
      })
      .then(res => {
        if (res.data.success == true) {
          onSuccess(res.data.res);
        } else {
          onError(res.data.message);
        }
      })
      .catch(error => {
        onError(error);
      });
  },
  deleteAgency(headers, params, onSuccess, onError) {
    axios
      .post(process.env.VUE_APP_API_URL + "agency/delete", params, {
        headers: headers
      })
      .then(res => {
        if (res.data.success == true) {
          onSuccess(res.data.res);
        } else {
          onError(res.data.message);
        }
      })
      .catch(error => {
        onError(error);
      });
  },
  updatePassword(headers, params, onSuccess, onError) {
    axios
      .post(process.env.VUE_APP_API_URL + "agency/password", params, {
        headers: headers
      })
      .then(res => {
        if (res.data.success == true) {
          onSuccess(res.data.res);
        } else {
          onError(res.data.message);
        }
      })
      .catch(error => {
        onError(error);
      });
  },
  getAgencyPhotos(headers, params, onSuccess, onError) {
    axios
      .post(process.env.VUE_APP_API_URL + "agency/get-photos", params, {
        headers: headers
      })
      .then(res => {
        if (res.data.success == true) {
          onSuccess(res.data.res);
        } else {
          onError(res.data.message);
        }
      })
      .catch(error => {
        onError(error);
      });
  },
  addAgencyPhoto(headers, params, onSuccess, onError) {
    axios
      .post(process.env.VUE_APP_API_URL + "agency/add-photo", params, {
        headers: headers
      })
      .then(res => {
        if (res.data.success == true) {
          onSuccess(res.data.res);
        } else {
          onError(res.data.message);
        }
      })
      .catch(error => {
        onError(error);
      });
  },
  removeAgencyPhoto(headers, params, onSuccess, onError) {
    axios
      .post(process.env.VUE_APP_API_URL + "agency/remove-photo", params, {
        headers: headers
      })
      .then(res => {
        if (res.data.success == true) {
          onSuccess(res.data.res);
        } else {
          onError(res.data.message);
        }
      })
      .catch(error => {
        onError(error);
      });
  },

  //Admin update
  fetchAdmin(headers, onSuccess, onError) {
    axios
      .post(process.env.VUE_APP_API_URL + "admin/profile", null, { headers })
      .then(res => {
        if (res.data.success == true) {
          onSuccess(res.data.res);
        } else {
          onError(res.data.message);
        }
      })
      .catch(error => {
        onError(error);
      });
  },
  updateAdmin(headers, params, onSuccess, onError) {
    axios
      .post(process.env.VUE_APP_API_URL + "admin/update", params, { headers })
      .then(res => {
        if (res.data.success == true) {
          onSuccess(res.data.res);
        } else {
          onError(res.data.message);
        }
      })
      .catch(error => {
        onError(error);
      });
  }
};
