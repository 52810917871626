import ApiManager from "@/api/ApiManager";
import axiosInstance from "@/api/axios";

export default {
  namespaced: true,
  state: {
    agency: null,
    agencies: []
  },
  getters: {
    getAllAgencies(state) {
      return state.agencies;
    },
    getAgency(state) {
      return state.agency;
    }
  },
  mutations: {
    setAgencies(state, data) {
      state.agencies = data;
    },
    setAgency(state, data) {
      state.agency = data;
    },
    addAgency(state, data) {
      const item = state.agencies.find(item => item._id === data._id);
      if (item) {
        Object.assign(item, data);
      } else {
        state.agencies.push(data);
      }
    },
    editAgency(state, data) {
      const item = state.agencies.find(item => item._id === data._id);
      if (item) {
        Object.assign(item, data);
      } else {
        state.agencies.push(data);
      }
    },
    deleteAgency(state, data) {
      state.agencies = state.agencies.filter(q => q._id !== data._id);
    },
    setStreets(state, data) {
      state.streets = data;
    },
    setStreetList(state, data) {
      state.streetList = data;
    }
  },
  actions: {
    fetchAllAgencies({ commit, rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("agency/all", params)
          .then(res => {
            if (res.data.success == true) {
              commit("setAgencies", res.data.res);
              resolve();
            } else {
              reject(res.data.message);
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    getAgency({ commit, rootState }, _id) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("agency/profile", { _id })
          .then(res => {
            if (res.data.success == true) {
              commit("setAgency", res.data.res);
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    addAgency({ commit, rootState }, params) {
      return new Promise((resolve, reject) => {
        ApiManager.addAgency(
          {
            Authorization: rootState.auth.token
          },
          params,
          agency => {
            commit("addAgency", agency);
            resolve();
          },
          error => {
            reject(error.response.data.message);
          }
        );
      });
    },
    editAgency({ commit, rootState }, params) {
      return new Promise((resolve, reject) => {
        ApiManager.editAgency(
          {
            Authorization: rootState.auth.token
          },
          params,
          agency => {
            if (agency) {
              commit("editAgency", agency);
            }
            resolve();
          },
          error => {
            reject(error);
          }
        );
      });
    },
    updateAgencyStatus({ commit, rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("agency/update-status", params)
          .then(res => {
            if (res.data.success == true) {
              commit("addAgency", res.data.res);
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    deleteAgency({ commit, rootState }, params) {
      return new Promise((resolve, reject) => {
        ApiManager.deleteAgency(
          {
            Authorization: rootState.auth.token
          },
          params,
          agency => {
            commit("deleteAgency", agency);
            resolve();
          },
          error => {
            reject(error);
          }
        );
      });
    },
    updatePassword({ rootState }, params) {
      return new Promise((resolve, reject) => {
        ApiManager.updatePassword(
          {
            Authorization: rootState.auth.token
          },
          params,
          () => {
            resolve();
          },
          error => {
            reject(error);
          }
        );
      });
    },
    getPhotos({ rootState }, _id) {
      return new Promise((resolve, reject) => {
        ApiManager.getAgencyPhotos(
          {
            Authorization: rootState.auth.token
          },
          {
            _id
          },
          res => {
            resolve(res);
          },
          error => {
            reject(error);
          }
        );
      });
    },
    addPhoto({ rootState }, params) {
      return new Promise((resolve, reject) => {
        ApiManager.addAgencyPhoto(
          {
            Authorization: rootState.auth.token
          },
          params,
          res => {
            resolve(res);
          },
          error => {
            reject(error);
          }
        );
      });
    },
    removePhoto({ rootState }, params) {
      return new Promise((resolve, reject) => {
        ApiManager.removeAgencyPhoto(
          {
            Authorization: rootState.auth.token
          },
          params,
          res => {
            resolve(res);
          },
          error => {
            reject(error);
          }
        );
      });
    },
    followAgency({ rootState, commit }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("agency/follow", params)
          .then(res => {
            if (res.data.success == true) {
              commit("auth/setProfile", res.data.res, { root: true });
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    getFanned({ rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("agency/fans", params)
          .then(res => {
            if (res.data.success == true) {
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    getVolunteerCities({ rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("agency/cities", { _id: params })
          .then(res => {
            if (res.data.success == true) {
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    copyFromProfile({ commit, rootState }) {
      commit("setAgency", rootState.auth.profile);
    },
    sendEmailVerification({ rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("agency/send-email-verification", params)
          .then(res => {
            if (res.data.success == true) {
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    sendAgencyEmail({ rootState }, params) {
      if (rootState.auth.type == "Agency") {
        return new Promise((resolve, reject) => {
          axiosInstance(rootState.auth.token)
            .post("agency/send-email", params)
            .then(res => {
              if (res.data.success == true) {
                resolve(res.data.message);
              } else {
                reject(res.data.message);
              }
            })
            .catch(error => {
              reject(error);
            });
        });
      }
    },
    sendAgencySMS({ rootState }, params) {
      if (rootState.auth.type == "Agency") {
        return new Promise((resolve, reject) => {
          axiosInstance(rootState.auth.token)
            .post("agency/send-sms", params)
            .then(res => {
              if (res.data.success == true) {
                resolve(res.data.message);
              } else {
                reject(res.data.message);
              }
            })
            .catch(error => {
              reject(error);
            });
        });
      }
    },
    connectBalance({ rootState }, params) {
      if (rootState.auth.type == "Agency") {
        return new Promise((resolve, reject) => {
          axiosInstance(rootState.auth.token)
            .post("agency/connect-balance", params)
            .then(res => {
              if (res.data.success == true) {
                resolve(res.data.message);
              } else {
                reject(res.data.message);
              }
            })
            .catch(error => {
              reject(error);
            });
        });
      }
    },
    getBalance({ rootState }, params) {
      if (rootState.auth.type == "Agency") {
        return new Promise((resolve, reject) => {
          axiosInstance(rootState.auth.token)
            .post("agency/get-balance", params)
            .then(res => {
              if (res.data.success == true) {
                resolve(res.data.res);
              } else {
                reject(res.data.message);
              }
            })
            .catch(error => {
              reject(error);
            });
        });
      }
    },
    createOnboardingUrl({ rootState }, params) {
      if (rootState.auth.type == "Agency") {
        return new Promise((resolve, reject) => {
          axiosInstance(rootState.auth.token)
            .post("agency/create-onboarding", params)
            .then(res => {
              if (res.data.success == true) {
                resolve(res.data.res);
              } else {
                reject(res.data.message);
              }
            })
            .catch(error => {
              reject(error);
            });
        });
      }
    }
  }
};
