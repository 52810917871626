import axiosInstance from "@/api/axios";

export default {
  namespaced: true,
  state: {
    volunteers: [],
    volunteer: null
  },
  getters: {
    getAllVolunteers(state) {
      return state.volunteers;
    },
    getAgencyVolunteers(state) {
      return state.agencyVolunteers;
    },
    getVolunteer(state) {
      return state.volunteer;
    }
  },
  mutations: {
    setVolunteers(state, data) {
      state.volunteers = data;
    },
    setVolunteer(state, data) {
      state.volunteer = data;
    },
    setAgencyVolunteers(state, data) {
      state.agencyVolunteers = data;
    },
    loadMoreVolunteers(state, data) {
      data.map(d => {
        if (state.volunteers.filter(e => e._id == d._id).length == 0) {
          state.volunteers.push(d);
        }
      });
    },
    loadMoreAgencyVolunteers(state, data) {
      data.map(d => {
        if (state.agencyVolunteers.indexOf(d) == -1)
          state.agencyVolunteers.push(d);
      });
    },
    addVolunteer(state, data) {
      const item = state.volunteers.find(item => item._id === data._id);
      if (item) {
        Object.assign(item, data);
      } else {
        state.volunteers.unshift(data);
      }
    },
    editVolunteer(state, data) {
      const item = state.volunteers.find(item => item._id === data._id);
      if (item) {
        Object.assign(item, data);
      } else {
        state.volunteers.push(data);
      }
    },
    viewVolunteer({ commit, rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("admin/getUserNeedlist", params, {
            headers: {
              Authorization: rootState.auth.token
            }
          })
          .then(res => {
            console.log("API Response:", res); // Log the API response
            if (res.data.success) {
              commit("viewVolunteer", res.data.res);
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch(error => {
            console.log("API Error:", error); // Log any errors
            reject(error);
          });
      });
    },
    deleteVolunteer(state, data) {
      state.volunteers = state.volunteers.filter(q => q._id !== data._id);
    }
  },
  actions: {
    fetchAllVolunteers({ commit, rootState }) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("users/all")
          .then(res => {
            if (res.data.success == true) {
              commit("setVolunteers", res.data.res);
              resolve();
            } else {
              reject(res.data.message);
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    loadMoreVolunteers({ commit, rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("volunteer/load-more", params)
          .then(res => {
            if (res.data.success == true) {
              commit("loadMoreVolunteers", res.data.res);
              resolve();
            } else {
              reject(res.data.message);
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    loadMoreAgencyVolunteers({ commit, rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("volunteer/load-more-agency", params)
          .then(res => {
            if (res.data.success == true) {
              commit("loadMoreVolunteers", res.data.res);
              resolve();
            } else {
              reject(res.data.message);
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    getVolunteer({ commit, rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("users/get", params)
          .then(res => {
            if (res.data.success == true) {
              commit("setVolunteer", res.data.res);
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    editVolunteer({ commit, rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("users/update-full", params, {
            Authorization: rootState.auth.token
          })
          .then(res => {
            if (res.data.success == true) {
              commit("editVolunteer", res.data.res);
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    viewVolunteer({ commit, rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("admin/getUserNeedlist", params, {
            Authorization: rootState.auth.token
          })
          .then(res => {
            console.log("API Response:", res); // Log the API response
            if (res.data.success == true) {
              commit("viewVolunteer", res.data.res);
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch(error => {
            console.log("API Error:", error); // Log any errors
            reject(error);
          });
      });
    },
    deleteVolunteer({ commit, rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("users/delete", params, {
            Authorization: rootState.auth.token
          })
          .then(res => {
            if (res.data.success == true) {
              commit("deleteVolunteer", res.data.res);
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    followAgency({ rootState, commit }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("users/follow-agency", params)
          .then(res => {
            if (res.data.success == true) {
              commit("auth/setProfile", res.data.res, { root: true });
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    followVolunteer({ rootState, commit }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("users/follow", params)
          .then(res => {
            if (res.data.success == true) {
              commit(
                "auth/updateProfile",
                { followers: res.data.res },
                { root: true }
              );
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    getFanned({ rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("users/fans", { _id: params })
          .then(res => {
            if (res.data.success == true) {
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    sendEmailVerification({ rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("users/send-email-verification", params)
          .then(res => {
            if (res.data.success == true) {
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    getWorkHours({ rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("users/hours", params)
          .then(res => {
            if (res.data.success == true) {
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    copyFromProfile({ commit, rootState }) {
      commit("setVolunteer", rootState.auth.profile);
    }
  }
};
