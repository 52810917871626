import axiosInstance from "@/api/axios";

export default {
  namespaced: true,
  state: {
    awards: []
  },
  getters: {
    getAllAwards(state) {
      return state.awards;
    }
  },
  mutations: {
    setAwards(state, data) {
      state.awards = data;
    },
    addAward(state, data) {
      const item = state.awards.find(item => item._id === data._id);
      if (item) {
        Object.assign(item, data);
      } else {
        state.awards.unshift(data);
      }
    },
    editAward(state, data) {
      const item = state.awards.find(item => item._id === data._id);
      if (item) {
        Object.assign(item, data);
      } else {
        state.awards.push(data);
      }
    },
    deleteAward(state, data) {
      state.awards = state.awards.filter(q => q._id !== data._id);
    },
    couponRedeem(state, data) {
      const item = state.awards.find(item => item._id === data._id);
      if (item) {
        Object.assign(item, data);
      } else {
        state.awards.unshift(data);
      }
    }
  },
  actions: {
    fetchAllAwards({ commit, rootState }) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("awards/all")
          .then(res => {
            if (res.data.success == true) {
              commit("setAwards", res.data.res);
              resolve();
            } else {
              reject(res.data.message);
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    getAward({ commit, rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("awards/get", params)
          .then(res => {
            if (res.data.success == true) {
              commit("setAward", res.data.res);
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    addAward({ commit, rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("awards/add", params, {
            Authorization: rootState.auth.token
          })
          .then(res => {
            if (res.data.success == true) {
              commit("addAward", res.data.res);
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    editAward({ commit, rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("awards/update", params, {
            Authorization: rootState.auth.token
          })
          .then(res => {
            if (res.data.success == true) {
              commit("editAward", res.data.res);
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    deleteAward({ commit, rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("awards/delete", params, {
            Authorization: rootState.auth.token
          })
          .then(res => {
            if (res.data.success == true) {
              commit("deleteAward", res.data.res);
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    reedemCoupon({ commit, rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("awards/reedem", params, {
            Authorization: rootState.auth.token
          })
          .then(res => {
            if (res.data.success == true) {
              commit("couponRedeem", res.data.res);
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    getEligibles({ rootState }) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("awards/eligibles", {
            Authorization: rootState.auth.token
          })
          .then(res => {
            if (res.data.success == true) {
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    getVolunteerHours({ rootState }, param) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("awards/volunteer-hours", param, {
            Authorization: rootState.auth.token
          })
          .then(res => {
            if (res.data.success == true) {
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    }
  }
};
