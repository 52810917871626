<template>
  <v-card
    elevation="8"
    class="card_home rounded-xl d-flex flex-column"
    @click="goToAgencies"
    color="white"
  >
    <v-card-title>
      Agencies
    </v-card-title>
    <v-card-text>
      In this section you can check the exact number of Agencies.
    </v-card-text>
    <v-card-title class="flex-grow py-0">
      <highcharts
        :options="chartOptions"
        class="homechart_container"
      ></highcharts>
    </v-card-title>
    <v-card-text class="d-flex mb-3">
      <div class="flex-grow d-flex flex-column align-center">
        <div class="primary-color font-24 app-bold-font">250</div>
        <div class="gray-400 mt-1">Registered</div>
      </div>
      <div class="flex-grow d-flex flex-column align-center">
        <div class="primary-color font-24 app-bold-font">10</div>
        <div class="gray-400 mt-1 text-center">Waiting approved</div>
      </div>
      <div class="flex-grow d-flex flex-column align-center">
        <div class="gray-500 font-24 app-bold-font">5</div>
        <div class="gray-400 mt-1">Uncompleted</div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import moment from "moment";

export default {
  data() {
    return {
      selected: "Volunteer Hours",
      menu: [
        {
          title: "Send message",
          reply: "Now",
          route: "admin-message",
          count: 0
        },
        {
          title: "Reported",
          reply: "Check more",
          route: "reported",
          count: 0
        },
        {
          title: "Blogs",
          reply: "Manage",
          route: "blog-feeds",
          count: 0
        }
      ],
      chartOptions: {
        showInLegend: false,
        chart: {
          renderTo: "container",
          borderRadius: 20,
          marginBottom: 50,
          marginLeft: 50,
          marginRight: 20,
          marginTop: 50
          // type: 'column',
        },
        credits: {
          enabled: false
        },
        title: {
          text: " " //Distributed Analysis'
        },
        // subtitle: {
        //     text: 'Test options by dragging the sliders below'
        // },
        plotOptions: {
          column: {
            depth: 25
          },
          series: {
            color: "#7024C4"
          }
        },
        xAxis: {
          labels: {
            formatter: function() {
              let myDate = new Date(this.value);
              return moment(String(myDate)).format("MMM DD");
            }
          }
        },
        yAxis: {
          title: ""
        },
        series: [
          {
            showInLegend: false,
            data: [
              [1356998400000, 10],
              [1359676800000, 22],
              [1362096000000, 30],
              [1364774400000, 156],
              [1367366400000, 85],
              [1370044800000, 101],
              [1372636800000, 152],
              [1375315200000, 254],
              [1377993600000, 321],
              [1380585600000, 576],
              [1383264000000, 870],
              [1385856000000, 541],
              [1388534400000, 320],
              [1391212800000, 200],
              [1393632000000, 454],
              [1396310400000, 700],
              [1398902400000, 50],
              [1401580800000, 100],
              [1404172800000, 200],
              [1406851200000, 500]
            ]
          }
        ],
        tooltip: {
          formatter: function() {
            let myDate = new Date(this.x);
            return moment(String(myDate)).format("MMM DD") + " : " + this.y;
          }
        }
      }
    };
  },
  methods: {
    goToAgencies() {
      this.$router.push({ name: "admin-agencies" });
    }
  },
  watch: {
    blogs(newValue) {
      this.menu[2].count = newValue;
    },
    reported(newValue) {
      this.menu[1].count = newValue;
    }
  }
};
</script>
<style scoped>
.card_home {
  height: 100%;
  bottom: 0;
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
}
.highcharts-container {
  height: 100% !important;
}
.highcharts-root {
  height: 100%;
}
.item_title {
  color: #472583;
  font-family: "Poppins-Medium";
  font-size: 20px;
  margin-left: 5px;
}
.homechart_container {
  min-height: 150px;
  height: 100%;
  width: 100%;
}
</style>
