<template>
  <v-card @click="onClicked" class="hover-item">
    <v-card-title
      class="d-flex flex-row"
      :class="{
        'pt-1 pb-1 pr-2': $vuetify.breakpoint.xs,
        'py-0': $vuetify.breakpoint.smAndUp
      }"
    >
      <div class="ml-2 mr-1">
        <v-img :src="icon" :width="30" :height="30" contain />
      </div>
      <div class="d-flex flex-column ml-3 align-left py-3">
        <span class="app-bold-font font-12">{{ title }}</span>
        <div class="d-none align-center mb-1">
          <div>
            <v-img
              :src="require('@/assets/svg/eye.svg')"
              :width="$vuetify.breakpoint.xs ? 15 : 15"
              :height="$vuetify.breakpoint.xs ? 8 : 8"
              contain
            />
          </div>
          <div
            class="gray-font-color ml-2 mt-1 line-height-15"
            :class="$vuetify.breakpoint.xs ? 'font-8' : 'font-8'"
          >
            {{ text }}
          </div>
        </div>
      </div>
      <div class="flex-grow d-flex justify-end">
        <v-icon color="#D6D6D8" size="20">mdi-arrow-right</v-icon>
      </div>
    </v-card-title>
    <v-card-title
      v-if="$vuetify.breakpoint.smAndUp && showSeperator"
      class="py-0"
    >
      <div style="height: 1px; background-color: #9DBDD0; width: 100%;"></div>
    </v-card-title>
  </v-card>
</template>
<script>
export default {
  props: {
    onClicked: Function,
    icon: String,
    title: String,
    text: String,
    top: {
      type: Boolean,
      default: true
    },
    showSeperator: {
      type: Boolean,
      default: true
    }
  }
};
</script>
