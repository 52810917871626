import axiosInstance from "@/api/axios";

export default {
  namespaced: true,
  state: {
    responses: []
  },
  getters: {
    getAllResponses(state) {
      return state.responses;
    }
  },
  mutations: {
    setResponses(state, data) {
      state.responses = data;
    },
    addResponse(state, data) {
      const item = state.responses.find(item => item._id === data._id);
      if (item) {
        Object.assign(item, data);
      } else {
        state.responses.unshift(data);
      }
    },
    editResponse(state, data) {
      const item = state.responses.find(item => item._id === data._id);
      if (item) {
        Object.assign(item, data);
      } else {
        state.responses.push(data);
      }
    },
    deleteResponse(state, data) {
      state.responses = state.responses.filter(q => q._id !== data._id);
    }
  },
  actions: {
    fetchAllResponses({ commit, rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("response/all", params)
          .then(res => {
            if (res.data.success == true) {
              commit("setResponses", res.data.res);
              resolve();
            } else {
              reject(res.data.message);
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    fetchUserResponses({ rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("response/user", params)
          .then(res => {
            if (res.data.success == true) {
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    confirmResponse({ rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("response/confirm", { _id: params })
          .then(res => {
            if (res.data.success == true) {
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    rejectResponse({ rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("response/reject", params)
          .then(res => {
            if (res.data.success == true) {
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    addResponse({ commit, rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("response/add", params)
          .then(res => {
            if (res.data.success == true) {
              commit("addResponse", res.data.res);
              resolve();
            } else {
              reject(res.data.message);
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    editResponse({ commit, rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("response/update", params, {
            Authorization: rootState.auth.token
          })
          .then(res => {
            if (res.data.success == true) {
              commit("editResponse", res.data.res);
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    deleteResponse({ commit, rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("response/delete", params)
          .then(res => {
            if (res.data.success == true) {
              commit("deleteResponse", res.data.res);
              resolve();
            } else {
              reject(res.data.message);
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    respondShift({ rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("response/add", params)
          .then(res => {
            if (res.data.success == true) {
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    onUpdateResponseNote({ rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("response/note", params)
          .then(res => {
            if (res.data.success == true) {
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    cancelResponse({ rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("response/cancel", params)
          .then(res => {
            if (res.data.success == true) {
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    }
  }
};
