import axiosInstance from "@/api/axios";

export default {
  namespaced: true,
  state: {
    members: [],
    competition:null,
    agencyMembers: []
  },
  getters: {
    getAllCompetitions(state) {
      return state.competitions;
    },
    getAgencyFollowers(state) {
      return state.agencyFollowers;
    }
  },
  mutations: {
    agencyMembers(state, data) {
      state.agencyMembers = data;
    },
    setAgencyFollowers(state, data) {
      state.agencyFollowers = data;
    },
    ADD_MEMBER(state, member) {
        state.members.push(member);
      },
  },
  actions: {
    fetchAllAgency({ commit, rootState }) {
        return new Promise((resolve, reject) => {
          axiosInstance(rootState.auth.token)
            .get("member/getVolunteerAgencies")
            .then(res => {
              if (res.data.agencies) {
                commit("agencyMembers", res.data.agencies);
                resolve(res.data.agencies);
              } else {
                reject(new Error(res.data.message)); 
              }
            })
            .catch(error => {
              reject(error);
            });
        });
      },
    addMember({ commit, rootState }, params) {
      return new Promise((resolve, reject) => {
      axiosInstance(rootState.auth.token)
        .post("member/joinAsMember", params, {
        Authorization: rootState.auth.token
        })
        .then(res => {
        if (res.data.member) {
          commit("ADD_MEMBER", res.data.member);
          resolve(res.data.member);
        } else {
          reject(res.data.message);
        }
        })
        .catch(error => {
        reject(error);
        });
      });
    },
  }
};
