<template>
  <div
    class="aichat-bottom"
    style="position: fixed; bottom: 0; right: 0; z-index: 2;"
    v-if="
      (profileType == 'Volunteer' || profileType == 'Agency') &&
        profile &&
        profile._id &&
        $route.name != 'chat' &&
        $route.name != 'chat-details' &&
        $route.name != 'login' &&
        $route.name != 'facebook-callback' &&
        $route.name != 'instagram-callback' &&
        $route.name != 'linkedin-callback' &&
        $route.name != 'twitter-callback' &&
        $route.name != 'tiktok-callback'
    "
  >
    <v-navigation-drawer
      v-model="drawer"
      :mini-variant.sync="mini"
      permanent
      :style="
        mini ? 'width: 288px; height: 47px' : 'height: 500px; width: 350px;'
      "
      color="#084468"
      class="rounded-lg rounded-b-0 d-flex justify-center"
      dark
    >
      <div class="d-flex">
        <div
          class="flex-grow white--text pt-3 pl-5 app-medium-font cursor-pointer"
          @click="mini = !mini"
        >
          AI Chat Support
        </div>
        <v-btn icon @click.stop="mini = !mini" class="mt-2 mr-1">
          <v-icon>{{ mini ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
        </v-btn>
      </div>
      <v-divider v-if="!mini" />
      <div
        class="pa-3 flex-grow overflow-auto"
        v-if="!mini"
        v-chat-scroll="{ always: false, smooth: false, notSmoothOnInit: true }"
        id="scroll-target"
      >
        <div
          v-for="(message, index) in messages"
          :key="index"
          class="bubble-container"
          :class="{ myMessage: message.role === 'user' }"
        >
          <div
            :class="{
              'd-flex': true,
              'flex-column': true,
              'first-message': message.index == 0
            }"
            :style="
              message.role === 'assistant'
                ? 'align-items: flex-start;'
                : 'align-items: flex-end;'
            "
          >
            <div
              style="max-width: 90%; position: relative"
              class="d-flex flex-row"
            >
              <div class="bubble">
                <div class="message text-block">
                  {{ message.content }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="sending"
          class="d-flex flex-row"
          style="margin-bottom: 19px; margin-left: 80px; font-size: 14px; color: lightgray;"
        >
          <div class="ml-1">
            <v-img
              src="@/assets/gif/typing.gif"
              contain
              height="20"
              width="30"
            />
          </div>
        </div>
      </div>
      <v-divider v-if="!mini" />
      <div class="d-flex" v-if="!mini">
        <v-textarea
          @keydown.enter.prevent="sendMessage"
          v-model="messageText"
          placeholder="Enter your message"
          flat
          solo
          hide-details
          ref="message"
          rows="1"
          auto-grow
          class="message-text-input"
        />
        <v-btn
          @click="sendMessage"
          icon
          class="ml-2 mt-1 mr-1"
          :loading="sending"
        >
          <v-icon size="20" class="mt-1" color="white">mdi-send</v-icon></v-btn
        >
      </div>
    </v-navigation-drawer>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
// import EditableAvatar from "@/components/EditableAvatar.vue";
export default {
  components: {},
  data() {
    return {
      drawer: true,
      mini: true,
      messages: [],
      messageText: "",
      sending: false
    };
  },
  methods: {
    ...mapActions({
      getAIChatMessage: "chat/getAIChatMessage"
    }),
    sendMessage(e) {
      if (this.messageText.length) {
        if (e.ctrlKey || e.altKey || e.shiftKey) {
          this.messageText += "\n";
          this.$refs.message.$refs.input.setSelectionRange(
            this.messageText.length,
            this.messageText.length
          );
          setTimeout(() => {
            let container = this.$el.querySelector("#scroll-target");
            container.scrollTop = container.scrollHeight;
          }, 50);
          return;
        }

        this.messages.push({ role: "user", content: this.messageText });
        this.messageText = "";
        this.sending = true;
        setTimeout(() => {
          let container = this.$el.querySelector("#scroll-target");
          container.scrollTop = container.scrollHeight;
        }, 100);
        this.getAIChatMessage({ chats: this.messages })
          .then(res => {
            this.sending = false;
            this.messages.push({ ...res[0].message });
            setTimeout(() => {
              let container = this.$el.querySelector("#scroll-target");
              container.scrollTop = container.scrollHeight;
            }, 500);
          })
          .catch(error => {
            this.sending = false;
            console.log(error.response.data.message);
          });
      }
    }
  },
  computed: {
    ...mapState("auth", {
      profile: "profile",
      profileType: "type"
    })
  },
  watch: {
    mini(newValue) {
      if (!newValue) {
        if (!this.messages.length) {
          this.sending = true;
          this.getAIChatMessage()
            .then(res => {
              this.sending = false;
              this.messages.push({ ...res[0].message });
              console.log(this.messages);
            })
            .catch(error => {
              this.sending = false;
              console.log(error.response.data.message);
            });
        }
      }
    }
  }
};
</script>
<style scoped>
div :deep(.v-navigation-drawer__content) {
  display: flex;
  flex-direction: column;
}
.bubble-container {
  text-align: left;
}
.bubble {
  border: 2px solid #f1f1f1;
  background-color: #fdfbfa;
  border-radius: 5px;
  padding: 10px;
  margin: 10px 20px;
  float: right;
  font-size: 14px;
}
.myMessage .bubble {
  background-color: #abf1ea;
  border: 2px solid #87e0d7;
  float: left;
}
.first-message {
  margin-top: 20px;
}
.last-message {
  margin-bottom: 50px;
}
.input-container {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
}
.message-section {
  border: solid;
  border-radius: 25px;
  border-width: 1px;
  border-color: #c1c1c1;
  background: white;
  width: 100%;
  display: flex;
  flex-direction: column;
}
@media (max-width: 600px) {
  .aichat-bottom {
    bottom: 56px !important;
  }
}
@media (max-width: 400px) {
  .v-navigation-drawer.v-navigation-drawer--is-mouseover.v-navigation-drawer--open.theme--dark {
    width: 100% !important;
  }
}
</style>
