import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/admin/home/AdminHome.vue";
import store from "@/store";

Vue.use(VueRouter);
const DEFAULT_TITLE = "aKinder™Volunteer";
const routes = [
  {
    path: "/",
    name: "home",
    component: () => import(/* webpackChunkName: "home" */ "../views/Home.vue"),
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: "/agency/home",
    name: "agency-home",
    component: () =>
      import(
        /* webpackChunkName: "agency-home" */ "../views/agency/AgencyHome.vue"
      ),
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: "/agency/approval",
    name: "agency-approval",
    component: () =>
      import(
        /* webpackChunkName: "agency-approval" */ "../views/agency/AgencyApproval.vue"
      ),
    props: true,
    meta: { requiresAuth: true, title: "Agency Approval | aKinder™Volunteer"  }
  },
  {
    path: "/agency/register",
    name: "agency-register",
    component: () =>
      import(
        /* webpackChunkName: "agency-register" */ "../views/getstarted/RegisterAgency.vue"
      ),
    props: true,
    meta: { requiresAuth: false, title: "Agency Register | aKinder™Volunteer" }
  },
  {
    path: "/user/register",
    name: "user-register",
    component: () =>
      import(
        /* webpackChunkName: "volunteer-register" */ "../views/getstarted/RegisterVolunteer.vue"
      ),
    props: true,
    meta: {
      requiresAuth: false,
      title: "Volunteer Register | aKinder™Volunteer"
    }
  },
  {
    path: "/volunteer/home",
    name: "volunteer-home",
    component: () =>
      import(
        /* webpackChunkName: "volunteer-home" */ "../views/volunteer/VolunteerHome.vue"
      ),
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: "/needs",
    name: "agency-needs",
    component: () =>
      import(
        /* webpackChunkName: "agency-needs" */ "../views/agency/needs/AgencyNeeds.vue"
      ),
    children: [
      {
        path: "feeds",
        name: "agency-need-feeds",
        meta: {
          requiresAuth: true,
          title: "Agencies Needs | aKinder™Volunteer"
        },
        component: () => import("../views/agency/needs/AgencyNeedFeeds.vue")
      },
      {
        path: "discover",
        name: "agency-need-discover",
        meta: {
          requiresAuth: true,
          title: "Agencie Needs | aKinder™Volunteer"
        },
        component: () => import("../views/agency/needs/AgencyNeedDiscover.vue")
      },
      {
        path: "notification",
        name: "agency-need-notification",
        meta: {
          requiresAuth: true,
          title: "Agencie Needs | aKinder™Volunteer"
        },
        component: () =>
          import("../views/agency/needs/AgencyNeedNotification.vue")
      },
      {
        path: "details",
        name: "agency-need-details",
        component: () =>
          import(
            /* webpackChunkName: "agency-need-details" */ "../views/agency/needs/AgencyNeedDetails.vue"
          ),
        props: true,
        meta: { requiresAuth: true }
      }
    ],
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: "/chat",
    name: "chat",
    component: () =>
      import(/* webpackChunkName: "notifications" */ "../views/chat/Chat.vue"),
    children: [
      {
        path: "details",
        name: "chat-details",
        meta: {
          requiresAuth: true,
          title: "Chat | aKinder™Volunteer"
        },
        component: () => import("../views/chat/ChatMessages.vue")
      },
      {
        path: "empty",
        name: "no-messages",
        meta: {
          requiresAuth: true,
          title: "No messages | aKinder™Volunteer"
        },
        component: () => import("../views/chat/NoMessages.vue")
      }
    ],
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: "/mobile-chat",
    name: "mobile-chat",
    meta: { requiresAuth: true, title: "Chat | aKinder™Volunteer" },
    component: () =>
      import(
        /* webpackChunkName: "notifications" */ "../views/chat/MobileChat.vue"
      )
  },
  {
    path: "/mobile-chat-room",
    name: "mobile-chat-room",
    meta: { requiresAuth: true, title: "Chat | aKinder™Volunteer" },
    component: () =>
      import(
        /* webpackChunkName: "notifications" */ "../views/chat/MobileChatMessages.vue"
      )
  },
  {
    path: "/agency-profile",
    name: "agency-profile",
    component: () =>
      import(
        /* webpackChunkName: "notifications" */ "../views/agency/profile/AgencyProfile.vue"
      ),
    children: [
      {
        path: ":id",
        name: "agency-profile-details",
        meta: {
          requiresAuth: true,
          title: "Profile | aKinder™Volunteer"
        },
        component: () =>
          import("../views/agency/profile/AgencyProfileDetails.vue"),
        children: [
          {
            path: "events",
            name: "agency-profile-events",
            meta: {
              requiresAuth: true,
              title: "Profile | aKinder™Volunteer"
            },
            component: () =>
              import("../views/agency/profile/AgencyProfileEvents.vue")
          },
          {
            path: "about",
            name: "agency-profile-about",
            meta: {
              requiresAuth: true,
              title: "Profile | aKinder™Volunteer"
            },
            component: () =>
              import("../views/agency/profile/AgencyProfileAbout.vue")
          },
          {
            path: "needs",
            name: "agency-profile-needs",
            meta: {
              requiresAuth: true,
              title: "Profile | aKinder™Volunteer"
            },
            component: () =>
              import("../views/agency/profile/AgencyProfileNeeds.vue")
          },
          {
            path: "volunteers",
            name: "agency-profile-volunteers",
            meta: {
              requiresAuth: true,
              title: "Profile | aKinder™Volunteer"
            },
            component: () =>
              import("../views/agency/profile/AgencyProfileVolunteers.vue")
          }
        ]
      }
    ],
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: "/volunteer-profile",
    name: "volunteer-profile",
    component: () =>
      import(
        /* webpackChunkName: "volunteer-profile" */ "../views/volunteer/profile/VolunteerProfile.vue"
      ),
    children: [
      {
        path: ":id",
        name: "volunteer-profile-details",
        meta: {
          requiresAuth: true,
          title: "Profile | aKinder™Volunteer"
        },
        component: () =>
          import("../views/volunteer/profile/VolunteerProfileDetails.vue"),
        children: [
          {
            path: "events",
            name: "volunteer-profile-events",
            meta: {
              requiresAuth: true,
              title: "Profile | aKinder™Volunteer"
            },
            component: () =>
              import("../views/volunteer/profile/VolunteerProfileEvents.vue")
          },
          {
            path: "about",
            name: "volunteer-profile-about",
            meta: {
              requiresAuth: true,
              title: "Profile | aKinder™Volunteer"
            },
            component: () =>
              import("../views/volunteer/profile/VolunteerProfileAbout.vue")
          },
          {
            path: "needs",
            name: "volunteer-profile-needs",
            meta: {
              requiresAuth: true,
              title: "Profile | aKinder™Volunteer"
            },
            component: () =>
              import("../views/volunteer/profile/VolunteerProfileNeeds.vue")
          },
          {
            path: "agencies",
            name: "volunteer-profile-agencies",
            meta: {
              requiresAuth: true,
              title: "Profile | aKinder™Volunteer"
            },
            component: () =>
              import("../views/volunteer/profile/VolunteerProfileAgencies.vue")
          }
        ]
      }
    ],
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: "/admin",
    name: "admin-home",
    component: Home,
    props: true,
    meta: {
      requiresAuth: true,
      title: "Admin | aKinder™Volunteer",
      permission: ["Admin"]
    }
  },
  {
    path: "/admin/volunteers",
    name: "admin-volunteers",
    meta: {
      requiresAuth: true,
      title: "Volunteers | aKinder™Volunteer",
      permission: ["Admin"]
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-volunteers" */ "../views/admin/volunteer/AdminVolunteers.vue"
      ),
    props: true
  },
  {
    path: "/admin/agencies",
    name: "admin-agencies",
    meta: {
      requiresAuth: true,
      title: "Agencies | aKinder™Volunteer",
      permission: ["Admin"]
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-agencies" */ "../views/admin/agency/AdminAgencies.vue"
      ),
    props: true
  },
  {
    path: "/admin/events",
    name: "events",
    meta: {
      requiresAuth: true,
      title: "Events | aKinder™Volunteer",
      permission: ["Admin"]
    },
    component: () =>
      import(/* webpackChunkName: "events" */ "../views/admin/AdminEvents.vue"),
    props: true
  },
  {
    path: "/admin/business",
    name: "admin-business",
    meta: {
      requiresAuth: true,
      title: "Business | aKinder™Volunteer",
      permission: ["Admin"]
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-business" */ "../views/admin/business/AdminBusinessHome.vue"
      ),
    props: true
  },
  {
    path: "/admin/ads",
    name: "admin-ads",
    meta: {
      requiresAuth: true,
      title: "Ads | aKinder™Volunteer",
      permission: ["Admin"]
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-ads" */ "../views/admin/ads/AdminAdsHome.vue"
      ),
    props: true
  },
  {
    path: "/reported",
    name: "reported",
    meta: {
      requiresAuth: true,
      title: "Reported | Admin",
      permission: ["Admin"]
    },
    component: () =>
      import(
        /* webpackChunkName: "reported" */ "../views/admin/report/AdminReported.vue"
      ),
    props: true
  },
  {
    path: "/event-details",
    name: "Event details",
    meta: { requiresAuth: true },
    component: () =>
      import(
        /* webpackChunkName: "event-details" */ "../views/event_feeds/details/EventDetailsHome.vue"
      ),
    props: true
  },
  {
    path: "/notifications",
    name: "notifications",
    meta: { requiresAuth: true },
    component: () =>
      import(
        /* webpackChunkName: "notifications" */ "../views/notification/NotificationHome.vue"
      ),
    props: true
  },
  {
    path: "/donations",
    meta: { requiresAuth: true },
    component: () =>
      import(
        /* webpackChunkName: "donation-home" */ "../views/donation/DonationHome.vue"
      ),
    children: [
      {
        path: "",
        name: "donation-select-agency",
        meta: {
          requiresAuth: true,
          title: "Select Agency | aKinder™Volunteer"
        },
        component: () => import("../views/donation/DonationSelectAgency.vue")
      },
      {
        path: "set-amount",
        name: "donation-set-amount",
        meta: {
          requiresAuth: true,
          title: "Set amount | aKinder™Volunteer"
        },
        component: () => import("../views/donation/DonationSetAmount.vue")
      },
      {
        path: "payment",
        name: "donation-payment",
        meta: {
          requiresAuth: true,
          title: "Donation | aKinder™Volunteer"
        },
        component: () => import("../views/donation/DonationPayment.vue")
      },
      {
        path: "success",
        name: "donation-success",
        meta: {
          requiresAuth: true,
          title: "Donation | aKinder™Volunteer"
        },
        component: () => import("../views/donation/DonationSuccess.vue")
      },
      {
        path: "history",
        name: "donation-history",
        meta: {
          requiresAuth: true,
          title: "My Donations | aKinder™Volunteer"
        },
        component: () => import("../views/donation/DonationHistory.vue")
      },
      {
        path: "details",
        name: "donation-details",
        meta: {
          requiresAuth: true,
          title: "Donation Details | aKinder™Volunteer"
        },
        component: () => import("../views/donation/DonationDetails.vue")
      }
    ],
    props: true
  },
  {
    path: "/competition",
    name: "competition",
    meta: { requiresAuth: true },
    component: () =>
      import("../views/competition/CompetitionHome.vue"),
    props: true
  },
  {
    path: "/search",
    name: "search",
    meta: { requiresAuth: true },
    component: () =>
      import(/* webpackChunkName: "search" */ "../views/search/SearchHome.vue"),
    props: true
  },
  {
    path: "/settings",
    name: "settings",
    meta: { requiresAuth: true, title: "Settings | aKinder™Volunteer" },
    component: () =>
      import(
        /* webpackChunkName: "settings" */ "../views/settings/SettingHome.vue"
      ),
    props: true
  },
  {
    path: "/admin-message",
    name: "admin-message",
    meta: {
      requiresAuth: true,
      title: "Message | aKinder™Volunteer",
      permission: ["Admin"]
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-message" */ "../views/admin/message/AdminMessageHome.vue"
      ),
    props: true
  },
  {
    path: "/agency-message",
    name: "agency-message",
    meta: {
      requiresAuth: true,
      title: "Message | aKinder™Volunteer",
      permission: ["Agency"]
    },
    component: () =>
      import(
        /* webpackChunkName: "agency-message" */ "../views/agency/message/AgencyMessageHome.vue"
      ),
    props: true
  },
  {
    path: "/need-map",
    name: "need-map",
    meta: { requiresAuth: true },
    component: () =>
      import(
        /* webpackChunkName: "need-map" */ "../views/need_map/NeedMapHome.vue"
      ),
    props: true
  },
  {
    path: "/weather",
    name: "weather",
    meta: { requiresAuth: true },
    component: () =>
      import(
        /* webpackChunkName: "weather" */ "../views/weather/WeatherHome.vue"
      ),
    props: true
  },
  {
    path: "/calendar",
    name: "calendar",
    meta: { requiresAuth: true },
    component: () =>
      import(
        /* webpackChunkName: "calendar" */ "../views/calendar/CalendarHome.vue"
      ),
    props: true
  },
  {
    path: "/awards",
    name: "awards",
    meta: { requiresAuth: true },
    component: () =>
      import(/* webpackChunkName: "awards" */ "../views/awards/AwardsHome.vue"),
    props: true
  },
  {
    path: "/awards/details",
    name: "award-details",
    meta: { requiresAuth: true },
    component: () =>
      import(
        /* webpackChunkName: "award-details" */ "../views/awards/AwardsDetails.vue"
      ),
    props: true
  },
  {
    path: "/volunteer-agencies",
    name: "volunteer-agencies",
    meta: { requiresAuth: true, title: "Agencies | aKinder™Volunteer" },
    component: () =>
      import(
        /* webpackChunkName: "volunteer-agencies" */ "../views/volunteer/agencies/VolunteerAgencies.vue"
      ),
    props: true
  },
  {
    path: "/volunteer-diary",
    name: "volunteer-diary",
    meta: { requiresAuth: true, title: "Agencies | aKinder™Volunteer" },
    component: () =>
      import(
        /* webpackChunkName: "volunteer-diary" */ "../views/volunteer/response/VolunteerDiary.vue"
      ),
    props: true
  },
  {
    path: "/agencies",
    name: "agencies",
    meta: { requiresAuth: true, title: "Agencies | aKinder™Volunteer" },
    component: () =>
      import(
        /* webpackChunkName: "agencies" */ "../views/agency/agencies/Agencies.vue"
      ),
    props: true
  },
  {
    path: "/agency-volunteers",
    name: "agency-volunteers",
    meta: { requiresAuth: true, title: "Volunteers | aKinder™Volunteer" },
    component: () =>
      import(
        /* webpackChunkName: "agency-volunteers" */ "../views/agency/volunteers/AgencyVolunteers.vue"
      ),
    props: true
  },
  {
    path: "/agency-responses",
    name: "agency-responses",
    meta: { requiresAuth: true, title: "Responses | aKinder™Volunteer" },
    component: () =>
      import(
        /* webpackChunkName: "agency-responses" */ "../views/agency/responses/AgencyResponses.vue"
      ),
    children: [
      {
        path: "feeds",
        name: "agency-responses-feeds",
        meta: {
          requiresAuth: true,
          title: "Responses | aKinder™Volunteer"
        },
        component: () =>
          import("../views/agency/responses/AgencyResponsesSection.vue")
      },
      {
        path: "diary",
        name: "agency-responses-diary",
        meta: { requiresAuth: true, title: "Work Diary | aKinder™Volunteer" },
        component: () =>
          import(
            /* webpackChunkName: "agency-responses-diary" */ "../views/agency/responses/AgencyVolunteerDiary.vue"
          ),
        props: true
      }
    ],
    props: true
  },
  {
    path: "/volunteers",
    name: "volunteers",
    meta: { requiresAuth: true, title: "Volunteers | aKinder™Volunteer" },
    component: () =>
      import(
        /* webpackChunkName: "volunteers" */ "../views/volunteer/volunteers/Volunteers.vue"
      ),
    props: true
  },
  {
    path: "/volunteer-hours",
    name: "volunteer-hours",
    meta: {
      requiresAuth: true,
      title: "Volunteers | aKinder™Volunteer",
      permission: ["Volunteer"]
    },
    component: () =>
      import(
        /* webpackChunkName: "volunteers" */ "../views/volunteer/work_history/VolunteerHours.vue"
      ),
    props: true
  },
  {
    path: "/join-as-member",
    name: "join-as-member",
    meta: {
      requiresAuth: true,
      title: "Volunteers Member | aKinder™Volunteer",
      permission: ["Volunteer"]
    },
    component: () =>
      import(
        /* webpackChunkName: "join-as-member" */ "../views/volunteer/members/MembersHome.vue"
      ),
    props: true
  },
  {
    path: "/edit-agency/:id",
    name: "edit-agency",
    meta: { requiresAuth: true, title: "Edit Agency | aKinder™Volunteer" },
    component: () =>
      import(
        /* webpackChunkName: "edit-agency" */ "../views/admin/agency/AdminEditAgency.vue"
      ),
    props: true
  },
  {
    path: "/support-center",
    name: "support-center",
    meta: { requiresAuth: true },
    component: () =>
      import(
        /* webpackChunkName: "support-center" */ "../views/SupportCenter.vue"
      ),
    props: true
  },
  {
    path: "/admin/donations",
    name: "admin-donations",
    meta: { requiresAuth: true, title: "Donations | aKinder™Volunteer" },
    component: () =>
      import(
        /* webpackChunkName: "admin-donations" */ "../views/admin/AdminDonations.vue"
      ),
    props: true
  },
  {
    path: "/blogs",
    name: "blogs",
    meta: { requiresAuth: true },
    component: () =>
      import(/* webpackChunkName: "blogs" */ "../views/blogs/BlogHome.vue"),
    children: [
      {
        path: "feeds",
        name: "blog-feeds",
        meta: {
          requiresAuth: false,
          title: "Blogs | aKinder™Volunteer"
        },
        component: () => import("../views/blogs/BlogFeeds.vue")
      },
      {
        path: "details",
        name: "blog-details",
        meta: { requiresAuth: true, title: "Blog | aKinder™Volunteer" },
        component: () =>
          import(
            /* webpackChunkName: "blog-details" */ "../views/blogs/BlogDetails.vue"
          ),
        props: true
      },
      {
        path: "create",
        name: "create-blog",
        meta: { requiresAuth: true },
        component: () =>
          import(
            /* webpackChunkName: "create-blog" */ "../views/blogs/CreateBlog.vue"
          ),
        props: true
      },
      {
        path: "edit",
        name: "edit-blog",
        meta: { requiresAuth: true },
        component: () =>
          import(
            /* webpackChunkName: "edit-blog" */ "../views/blogs/EditBlog.vue"
          ),
        props: true
      }
    ],
    props: true
  },
  {
    path: "/admin-profile",
    name: "admin-profile",
    meta: { requiresAuth: true, title: "Admin Profile | aKinder™Volunteer" },
    component: () =>
      import(
        /* webpackChunkName: "admin-profile" */ "../views/admin/profile/AdminProfile.vue"
      ),
    props: true
  },
  {
    path: "/landing",
    name: "landing",
    meta: { requiresAuth: false, title: "aKinder™Volunteer" },
    component: () =>
      import(
        /* webpackChunkName: "landing" */ "../views/getstarted/Landing.vue"
      ),
    props: true
  },
  {
    path: "/login",
    name: "login",
    meta: { requiresAuth: false, title: "Login | aKinder™Volunteer" },
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/getstarted/Login.vue"),
    props: true
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    meta: { requiresAuth: false, title: "Forgot Password | aKinder™Volunteer" },
    component: () =>
      import(
        /* webpackChunkName: "forgot-password" */ "../views/getstarted/ForgotPassword.vue"
      ),
    props: true
  },
  {
    path: "/verification-code",
    name: "verification-code",
    meta: {
      requiresAuth: false,
      title: "Verification code | aKinder™Volunteer"
    },
    component: () =>
      import(
        /* webpackChunkName: "verification-code" */ "../views/getstarted/VerificationCode.vue"
      ),
    props: true
  },
  {
    path: "/reset-password",
    name: "reset-password",
    meta: { requiresAuth: false, title: "Reset password | aKinder™Volunteer" },
    component: () =>
      import(
        /* webpackChunkName: "reset-password" */ "../views/getstarted/ResetPassword.vue"
      ),
    props: true
  },
  {
    path: "/linker",
    name: "linker",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Linker.vue"),
    props: true
  },
  {
    path: "/privacy",
    name: "privacy",
    meta: {
      requiresAuth: false,
      title: "Privacy and Policy | aKinder™Volunteer"
    },
    component: () =>
      import(/* webpackChunkName: "users" */ "../views/Policy.vue"),
    props: true
  },
  {
    path: "/tos",
    name: "terms-of-service",
    meta: {
      requiresAuth: false,
      title: "Terms of Service | aKinder™Volunteer"
    },
    component: () =>
      import(/* webpackChunkName: "users" */ "../views/TermsOfService.vue"),
    props: true
  },
  {
    path: "/aboutus",
    name: "aboutus",
    meta: {
      requiresAuth: false,
      title: "About | aKinder™Volunteer"
    },
    component: () =>
      import(/* webpackChunkName: "users" */ "../views/About.vue"),
    props: true
  },
  {
    path: "/facebook-callback",
    name: "facebook-callback",
    meta: {
      requiresAuth: false,
      title: "aKinder™Volunteer"
    },
    component: () =>
      import(
        /* webpackChunkName: "users" */ "../views/settings/social_callbacks/FacebookCallback.vue"
      ),
    props: true
  },
  {
    path: "/instagram-callback",
    name: "instagram-callback",
    meta: {
      requiresAuth: false,
      title: "aKinder™Volunteer"
    },
    component: () =>
      import(
        /* webpackChunkName: "users" */ "../views/settings/social_callbacks/InstagramCallback.vue"
      ),
    props: true
  },
  {
    path: "/linkedin-callback",
    name: "linkedin-callback",
    meta: {
      requiresAuth: false,
      title: "aKinder™Volunteer"
    },
    component: () =>
      import(
        /* webpackChunkName: "users" */ "../views/settings/social_callbacks/LinkedInCallback.vue"
      ),
    props: true
  },
  {
    path: "/twitter-callback",
    name: "twitter-callback",
    meta: {
      requiresAuth: false,
      title: "aKinder™Volunteer"
    },
    component: () =>
      import(
        /* webpackChunkName: "users" */ "../views/settings/social_callbacks/TwitterCallback.vue"
      ),
    props: true
  },
  {
    path: "/tiktok-callback",
    name: "tiktok-callback",
    meta: {
      requiresAuth: false,
      title: "aKinder™Volunteer"
    },
    component: () =>
      import(
        /* webpackChunkName: "users" */ "../views/settings/social_callbacks/TiktokCallback.vue"
      ),
    props: true
  },
  {
    path: "/404",
    name: "404",
    component: () => import("../views/NotFound.vue")
  },
  {
    path: "/*",
    redirect: "/404"
  }
];

const router = new VueRouter({
  mode: "history",
  routes
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title || DEFAULT_TITLE;
  if (to.meta.requiresAuth) {
    if (store.getters["auth/isAuthenticated"]) {
      if (
        to.meta.permission &&
        !to.meta.permission.includes(store.getters["auth/getType"])
      ) {
        router.push({ name: "home" });
      } else {
        next();
        store.commit("chat/setRoute", to);
      }
    } else {
      next({
        name: "landing",
        query: {
          redirect: to.fullPath
        }
      });
    }
  } else {
    next();
  }
});

router.onError(error => {
  if (/loading chunk [a-z0-9-]* failed./i.test(error.message)) {
    window.location.reload();
  }
});

export default router;
