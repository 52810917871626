import axiosInstance from "@/api/axios";
import { Client as ConversationsClient } from "@twilio/conversations";
import Vue from "vue";

export default {
  namespaced: true,
  state: {
    status: null,
    conversationsClient: null,
    conversations: [],
    unreadCount: {},
    currentRoute: null,
    loading: false,
    initializing: false
  },
  getters: {
    getStatus(state) {
      return state.status;
    },
    conversationsClient(state) {
      return state.conversationsClient;
    },
    getConversations(state) {
      return state.conversations;
    },
    getUnreadMessagesCount(state) {
      return Object.values(state.unreadCount).reduce((a, b) => a + b, 0);
    }
  },
  mutations: {
    setStatus(state, data) {
      state.status = data;
    },
    setLoading(state, data) {
      state.loading = data;
    },
    setInitializing(state, data) {
      state.initializing = data;
    },
    setConversations(state, data) {
      var array = data;
      array.sort((a, b) => {
        var aTime = new Date(a.date);
        var bTime = new Date(b.date);
        if (aTime.getTime() > bTime.getTime()) return -1;
        else if (aTime.getTime() < bTime.getTime()) return 1;
        return 0;
      });
      state.conversations = array;
    },
    setConversation(state, data) {
      state.conversations = state.conversations.map(c =>
        c.uniqueName == data.uniqueName ? data : c
      );
    },
    conversationsClient(state, data) {
      state.conversationsClient = data;
    },
    setUnreadMessageCount(state, param) {
      state.unreadCount[param.key] = param.value;
      state.unreadCount = { ...state.unreadCount };
    },
    setRoute(state, route) {
      state.currentRoute = route;
    }
  },
  actions: {
    initConversationsClient({ commit, state, dispatch, rootState }) {
      console.log("request init conversation");
      if (state.initializing) return;
      commit("setInitializing", true);
      console.log("init conversation");
      window.conversationsClient = ConversationsClient;
      return new Promise((resolve, reject) => {
        if (state.conversationsClient) {
          commit("setInitializing", false);
          console.log("exist conversation, exit!");
          resolve();
          return;
        }
        axiosInstance(rootState.auth.token)
          .post("chat/get-token")
          .then(res => {
            commit("setInitializing", false);
            if (res.data.success == true) {
              var conversationsClient = new ConversationsClient(res.data.res);
              commit("setStatus", "connecting");
              conversationsClient.on("connectionStateChanged", state => {
                commit("setStatus", state);
              });
              // conversationsClient.on("conversationAdded", (state) => {
              //   console.log("conversation added", state);
              // });
              // conversationsClient.on("conversationJoined", (state) => {
              //   console.log("conversation joined", state);
              // });
              conversationsClient.on("conversationLeft", () => {
                dispatch("getAllConversations");
              });
              conversationsClient.on("conversationRemoved", () => {
                dispatch("getAllConversations");
              });
              conversationsClient.on("conversationUpdated", () => {
                dispatch("getAllConversations");
              });
              conversationsClient.on("messageAdded", async message => {
                if (
                  state.currentRoute.name !== "chat-details" &&
                  state.currentRoute.name !== "mobile-chat-room"
                ) {
                  dispatch("getAllConversations");
                  let body = "";
                  try {
                    let obj = JSON.parse(message.body);
                    body = obj.body;
                  } catch (error) {
                    body = message.body;
                  }
                  let senderName = "";
                  try {
                    res = await axiosInstance(
                      rootState.auth.token
                    ).post("chat/sender-name", { sender: message.author });
                    senderName = res.data.res;
                  } catch (error) {
                    console.log(error);
                  }
                  console.log(senderName);
                  Vue.notification.requestPermission();
                  if (!senderName) {
                    Vue.notify({
                      group: "message",
                      title: "New message",
                      text: body
                    });

                    Vue.notification.show(
                      "New message",
                      {
                        body
                      },
                      {}
                    );
                  } else {
                    Vue.notify({
                      group: "message",
                      title: "New message from " + senderName,
                      text: body
                    });

                    Vue.notification.show(
                      "New message from " + senderName,
                      {
                        body
                      },
                      {}
                    );
                  }
                }
              });
              conversationsClient.on("messageRemoved", state => {
                console.log("message removed", state);
              });
              conversationsClient.on("messageUpdated", state => {
                console.log("message updated", state);
              });
              conversationsClient.on("participantJoined", state => {
                console.log("participantJoined", state);
              });
              conversationsClient.on("participantLeft", state => {
                console.log("participantLeft"), state;
              });
              // conversationsClient.on("participantUpdated", (state) => {
              //   console.log("participantUpdated", state);
              // });
              conversationsClient.on("pushNotification", state => {
                console.log("pushNotification", state);
              });
              conversationsClient.on("stateChanged", state => {
                console.log(state);
                dispatch("getAllConversations");
              });
              // conversationsClient.on("userUpdated", (state) => {
              //   console.log("userUpdated", state);
              // });
              commit("conversationsClient", conversationsClient);
              dispatch("getAllConversations");
              resolve();
            } else {
              reject(res.data.message);
            }
          })
          .catch(error => {
            commit("setInitializing", false);
            console.log(error);
            reject(error);
          });
      });
    },
    getAllConversations({ state, commit, dispatch, rootState }) {
      if (state.loading) return;
      commit("setLoading", true);
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("chat/all")
          .then(res => {
            commit("setLoading", false);
            if (res.data.success == true) {
              commit("setConversations", res.data.res);
              dispatch("checkNewMessages");
              resolve();
            } else {
              reject(res.data.message);
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    updateConversation({ commit, rootState }, param) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("chat/update-conversation", param)
          .then(res => {
            if (res.data.success == true) {
              commit("setConversation", res.data.res);
              resolve();
            } else {
              reject(res.data.message);
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    checkNewMessages({ commit, dispatch, state }) {
      if (
        !state.conversationsClient ||
        !state.conversations ||
        !state.conversations.length
      )
        return;
      state.conversations.map(c => {
        state.conversationsClient
          .getConversationByUniqueName(c.uniqueName)
          .then(async conv => {
            if (conv.lastReadMessageIndex == null) {
              conv.updateLastReadMessageIndex(0);
              commit("setUnreadMessageCount", {
                key: c.uniqueName,
                value: 1
              });
            } else {
              conv.getUnreadMessagesCount().then(res => {
                commit("setUnreadMessageCount", {
                  key: c.uniqueName,
                  value: res
                });
              });
            }
          })
          .catch(error => {
            console.log(
              "get active conversation failed",
              error ? error.message : error
            );
            if (
              error.message ==
                "Unable to connect: Connection is not initialized" ||
              error.message == "Unable to connect: disconnected"
            ) {
              commit("conversationsClient", null);
              dispatch("initConversationsClient");
            }
          });
      });
    },
    addChat({ rootState }, id) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("chat/add", { id })
          .then(res => {
            if (res.data.success == true) {
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    deleteChat({ dispatch, rootState }, id) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("chat/delete", { id })
          .then(res => {
            if (res.data.success == true) {
              dispatch("getAllConversations");
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    searchUsers({ rootState }, key) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("chat/search-users", { key })
          .then(res => {
            if (res.data.success == true) {
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    searchUsersEmail({ rootState }, key) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("chat/search-users-email", { key })
          .then(res => {
            if (res.data.success == true) {
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    searchVolunteersEmail({ rootState }, key) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("chat/search-volunteers-email", { key })
          .then(res => {
            if (res.data.success == true) {
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    searchUsersPhone({ rootState }, key) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("chat/search-users-phone", { key })
          .then(res => {
            if (res.data.success == true) {
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    searchVolunteersPhone({ rootState }, key) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("chat/search-volunteers-phone", { key })
          .then(res => {
            if (res.data.success == true) {
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    sendMessageNotification({ rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("chat/notification", params)
          .then(res => {
            if (res.data.success == true) {
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    getAIChatMessage({ rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("chat/ai", params)
          .then(res => {
            if (res.data.success == true) {
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    }
  }
};
