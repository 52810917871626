<template>
  <v-card
    elevation="8"
    class="card_home rounded-xl d-flex flex-column"
    @click="goToVolunteers"
    color="white"
  >
    <v-card-title>
      Volunteers
    </v-card-title>
    <v-card-text>
      In this section you can check the exact number of Volunteers.
    </v-card-text>
    <v-card-title class="flex-grow pa-0">
      <highcharts
        :options="chartOptions"
        class="homechart_container"
      ></highcharts>
    </v-card-title>
    <v-card-text class="d-flex mb-3">
      <div class="flex-grow d-flex flex-column align-center">
        <div class="primary-color font-24 app-bold-font">25132</div>
        <div class="gray-400 mt-1">Total</div>
      </div>
      <div class="flex-grow d-flex flex-column align-center">
        <div class="primary-color font-24 app-bold-font">1541</div>
        <div class="gray-400 mt-1">Working</div>
      </div>
      <div class="flex-grow d-flex flex-column align-center">
        <div class="gray-500 font-24 app-bold-font">53261</div>
        <div class="gray-400 mt-1">Hours</div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      selected: "Volunteer Hours",
      menu: [
        {
          title: "Send message",
          reply: "Now",
          route: "admin-message",
          count: 0
        },
        {
          title: "Reported",
          reply: "Check more",
          route: "reported",
          count: 0
        },
        {
          title: "Blogs",
          reply: "Manage",
          route: "blog-feeds",
          count: 0
        }
      ],
      chartOptions: {
        showInLegend: false,
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: "pie",
          backgroundColor: "white",
          renderTo: "container"
        },
        title: {
          text: ""
        },
        credits: {
          enabled: false
        },
        tooltip: {
          pointFormat: "{point.percentage:.1f}%"
        },
        accessibility: {
          point: {
            valueSuffix: "%"
          }
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: "pointer",
            dataLabels: {
              enabled: true,
              format: "<b>{point.name}</b>: {point.percentage:.1f} %"
            }
          }
        },
        series: [
          {
            colorByPoint: true,
            name: "Age group",
            data: [
              {
                name: "13-18",
                y: 123
              },
              {
                name: "19-24",
                y: 189
              },
              {
                name: "25-34",
                y: 57
              },
              {
                name: "35-44",
                y: 34
              },
              {
                name: "45-54",
                y: 25
              },
              {
                name: "55-64",
                y: 7
              },
              {
                name: "65 and older",
                y: 2
              }
            ]
          }
        ]
      }
    };
  },
  methods: {
    goToVolunteers() {
      this.$router.push({ name: "admin-volunteers" });
    }
  }
};
</script>
<style scoped>
.card_home {
  height: 100%;
  bottom: 0;
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
}
.highcharts-container {
  height: 100% !important;
}
.highcharts-root {
  height: 100%;
}
.item_title {
  color: #472583;
  font-family: "Poppins-Medium";
  font-size: 20px;
  margin-left: 5px;
}
.homechart_container {
  min-height: 250px;
  height: 100%;
  width: 100%;
}
</style>
