import axiosInstance from "@/api/axios";

export default {
  namespaced: true,
  state: {
    competitions: [],
    competition:null,
    agencyFollowers: []
  },
  getters: {
    getAllCompetitions(state) {
      return state.competitions;
    },
    getAgencyFollowers(state) {
      return state.agencyFollowers;
    }
  },
  mutations: {
    setCompetitions(state, data) {
      state.competitions = data;
    },
    setAgencyFollowers(state, data) {
      state.agencyFollowers = data;
    },
    addCompetition(state, data) {
      const item = state.competitions.find(item => item._id === data._id);
      if (item) {
        Object.assign(item, data);
      } else {
        state.competitions.unshift(data);
      }
    },
    editCompetition(state, data) {
      const index = state.competitions.findIndex(item => item._id === data._id);
      if (index !== -1) {
        state.competitions.splice(index, 1, data);
      } else {
        state.competitions.push(data);
      }
    },
    setCompetition(state, competition) {
      state.competition = competition;
    },
    deleteCompetition(state, data) {
      state.competitions = state.competitions.filter(q => q._id !== data._id);
    }
  },
  actions: {
    fetchAllCompetitions({ commit, rootState }) {
        return new Promise((resolve, reject) => {
          axiosInstance(rootState.auth.token)
            .get("competition/getAllCompetitions")
            .then(res => {
              if (res.data.competitions) {
                commit("setCompetitions", res.data.competitions);
                resolve(res.data.competitions);
              } else {
                reject(new Error(res.data.message)); 
              }
            })
            .catch(error => {
              reject(error);
            });
        });
      },
      getCompetition({ commit, rootState }, params) {
        return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("competition/getAgencyFollowers", params, {
          Authorization: rootState.auth.token
          })
          .then(res => {
          if (res.data.success == true || res.data.followers) {
            commit("setAgencyFollowers", res.data.followers);
            resolve(res.data.followers);
          } else {
            reject(res.data.message);
          }
          })
          .catch(error => {
          reject(error);
          });
        });
      },
    addCompetition({ commit, rootState }, params) {
      return new Promise((resolve, reject) => {
      axiosInstance(rootState.auth.token)
        .post("competition/createCompetition", params, {
        Authorization: rootState.auth.token
        })
        .then(res => {
        if (res.data.competition) {
          commit("addCompetition", res.data.competition);
          resolve(res.data.competition);
        } else {
          reject(res.data.message);
        }
        })
        .catch(error => {
        reject(error);
        });
      });
    },
    editCompetition({ commit, rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("competition/updateCompetition", params, {
            Authorization: rootState.auth.token
          })
          .then(res => {
            if (res.data.competition) {
              commit("editCompetition", res.data.competition);
              resolve(res.data.competition);
            } else {
              reject(res.data.message);
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    detailedCompetition({ commit, rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("competitionTeam/getCompetitionTeamsByCompetitionId", params, {
            Authorization: rootState.auth.token
          })
          .then(res => {
            console.log(res,'ressss');
            
            if (res.data.competition_detailedView) {
              commit("setCompetition", res.data.competition_detailedView);
              resolve(res.data.competition_detailedView);
            } else {
              reject(res.data.message);
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    deleteCompetition({ commit, rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("competition/deleteCompetition", params, {
            Authorization: rootState.auth.token
          })
          .then(res => {
            if (res.data.competition) {
              commit("deleteCompetition", res.data.competition);
              resolve(res.data.competition);
            } else {
              reject(res.data.message);
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    }
  }
};
