import axios from "axios";

/**
 * Create Axios Instance
 */
const createInstance = (token = "", timeout = 60000) => {
  const axiosInstance = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    headers: {
      ...(token ? { Authorization: token } : {})
    },
    timeout
  });

  axiosInstance.interceptors.request.use(config => {
    // ----------------------------- API Call Start ----------------------------- /
    / eslint-disable no-console /;
    // console.log("[===== Started API Call =====]");
    return config;
  });

  axiosInstance.interceptors.response.use(
    response => {
      // ------------------------------ API Call End ------------------------------ /
      // console.log("[===== Ended API Call =====]");
      return response;
    },
    error => {
      if (
        error.code === "ECONNABORTED" &&
        (!error.response || error.response.status !== 204)
      ) {
        if (error.response) console.log(error.response.status);
        else console.log("error.response is null");
        //We need to check more about this issue.
        location.reload();
      } else {
        return Promise.reject(error);
      }
    }
  );

  return axiosInstance;
};

export default createInstance;
